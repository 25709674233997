import { useState } from 'react'
import Head from 'next/head'
import { PAGE_NAME, DEFAULT_METADATA } from '@/data/metadata'
import { IMetadata } from '@/ts/models'

type PageHeadProps = {
  metadata?: IMetadata
  disabledSEO?: boolean
  children?: any
}

function PageHead({ metadata, children, disabledSEO }: PageHeadProps) {
  const [localMetadata] = useState<IMetadata>({
    title: metadata?.title || DEFAULT_METADATA.title,
    description: metadata?.description || DEFAULT_METADATA.description,
    keywords: metadata?.keywords || DEFAULT_METADATA.keywords,
    ogUrl: metadata?.ogUrl || DEFAULT_METADATA.ogUrl,
    ogType: metadata?.ogType || DEFAULT_METADATA.ogType,
    ogImage: metadata?.ogImage || DEFAULT_METADATA.ogImage,
    imageWidth: metadata?.imageWidth || '1440',
    imageHeight: metadata?.imageHeight || '300',
    imageAlt: metadata?.imageAlt || DEFAULT_METADATA.imageAlt,
  })

  return (
    <Head>
      <title>{`${localMetadata.title} | ${PAGE_NAME}`}</title>
      {/*Article Specific Metadata*/}
      <meta name="description" content={localMetadata.description} />
      <meta name="keywords" content={localMetadata?.keywords?.toString()} />
      {/* <meta name="article-author" content="" /> */}

      {/*Open Graph Metadata*/}
      <meta property="og:type" content={localMetadata.ogType} />
      <meta property="og:title" content={localMetadata.title} />
      <meta property="og:description" content={localMetadata.description} />
      <meta property="og:image" content={localMetadata.ogImage} />
      <meta property="og:image:secure_url" content={localMetadata.ogImage} />
      <meta property="og:image:width" content={localMetadata.imageWidth} />
      <meta property="og:image:height" content={localMetadata.imageHeight} />
      <meta property="og:site_name" content={PAGE_NAME} />
      <meta property="og:url" content={localMetadata.ogUrl} />
      <meta property="og:image:alt" content={localMetadata.imageAlt} />

      {/*Twitter Card Metadata*/}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={localMetadata.ogUrl} />
      <meta name="twitter:title" content={localMetadata.title} />
      <meta name="twitter:description" content={localMetadata.description} />
      <meta name="twitter:image:src" content={localMetadata.ogImage} />
      {children}

      {disabledSEO && <meta name="robots" content="noindex, nofollow" />}
      {/*<Script  src="https://cdn.tailwindcss.com" />*/}
      <script src="https://cdn.tailwindcss.com" />

      <script
        defer
        src="https://cdn.jsdelivr.net/npm/keen-slider@6.8.5/keen-slider.js"
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/keen-slider@6.8.5/keen-slider.min.css"
      />
      <script
        defer
        src="https://cdn.jsdelivr.net/npm/alpinejs@3.x.x/dist/cdn.min.js"
      />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.8.0/datepicker.min.js"></script>
    </Head>
  )
}

export default PageHead
