//COMPONENTS
import { FC, Key, useState } from 'react'

import { useKeenSlider } from 'keen-slider/react'
import Image, { ImageProps } from 'next/image'
import 'keen-slider/keen-slider.min.css'

export interface GallerySliderItem {
  key?: Key | null
  alt?: string
  src?: ImageProps['src']
}
interface ImageGallerySliderProps {
  galleryMap: GallerySliderItem[]
}

const ImageGallerySlider: FC<ImageGallerySliderProps> = props => {
  const { galleryMap } = props
  const [loaded, setLoaded] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, instanceRef] = useKeenSlider({
    slideChanged(slide) {
      setCurrentSlide(slide.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    loop: true,
  })

  return (
    <>
      <div ref={sliderRef} className="keen-slider tour_gallery--container">
        {galleryMap?.map(({ key, alt, src }, galleryIndex) => {
          return (
            <div
              className="keen-slider__slide"
              key={`${key}-${galleryIndex}`}
              style={{ paddingBottom: '56.25%' }}
            >
              <Image
                src={src}
                layout="fill"
                objectFit="cover"
                alt={alt || 'Mr Biker Saigon'}
              />
            </div>
          )
        })}
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {Array.from(instanceRef?.current?.track?.details?.slides?.keys()).map(
            idx => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx)
                  }}
                  className={'dot' + (currentSlide === idx ? ' active' : '')}
                ></button>
              )
            }
          )}
        </div>
      )}
    </>
  )
}

export default ImageGallerySlider
