import { FC } from 'react'
import { HTMLBuilderParserProps } from '.'
import { getValueByField } from './utils'

const PlaceholderText: FC<HTMLBuilderParserProps> = props => {
  const { className, component, data } = props
  const { field } = component

  const text = getValueByField(data, field)

  return (
    <div className={className} {...component.attributes}>
      {text}
    </div>
  )
}

export default PlaceholderText
