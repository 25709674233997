import { GRAPEJS_CUSTOM_BLOCKS } from '@/ts/constant'
import { HTMLBuilderParserProps } from '.'
import ToggleCurrencies from '@/components/Currencies/ToggleCurrencies'
import ShopSideBar from '@/components/ShopSidebar'
import LanguageSwitcher from '@/components/LanguageSwitcher'

function handleCustomComponent(props: HTMLBuilderParserProps) {
  const { component } = props
  const { attributes } = component

  if (attributes?.id == GRAPEJS_CUSTOM_BLOCKS.ToggleCurrencies) {
    return <ToggleCurrencies />
  }

  if (attributes?.id == GRAPEJS_CUSTOM_BLOCKS.ShopSideBar) {
    return (
      <div className="shop-sidebar">
        <ShopSideBar />
      </div>
    )
  }

  if (attributes?.id == GRAPEJS_CUSTOM_BLOCKS.LanguageSwitcher) {
    return (
      <div className={GRAPEJS_CUSTOM_BLOCKS.LanguageSwitcher}>
        <LanguageSwitcher />
      </div>
    )
  }

  return null
}

export default handleCustomComponent
