import { useRouter } from 'next/router'
export default function LanguageSwitcher() {
  const router = useRouter()

  const handleSwitchLangugeVN = () => {
    router.push(router.asPath, router.asPath, { locale: 'vi-VN' })
  }

  const handleSwitchLangugeEN = () => {
    router.push(router.asPath, router.asPath, { locale: 'en-US' })
  }

  return (
    <div className="flex">
      <button className="w-5 mr-2" onClick={handleSwitchLangugeEN}>
        <svg
          className="h-full w-auto"
          viewBox="0 0 20 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_270_60980"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="15"
          >
            <rect width="20" height="15" fill="white" />
          </mask>
          <g mask="url(#mask0_270_60980)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0V15H20V0H0Z"
              fill="#2E42A5"
            />
            <mask
              id="mask1_270_60980"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="20"
              height="15"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0V15H20V0H0Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_270_60980)">
              <mask
                id="mask2_270_60980"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="15"
              >
                <rect width="20" height="15" fill="white" />
              </mask>
              <g mask="url(#mask2_270_60980)">
                <path
                  d="M-2.22696 13.9284L2.1741 15.7896L20.0999 2.02363L22.4213 -0.742296L17.715 -1.36439L10.4035 4.56778L4.51853 8.56467L-2.22696 13.9284Z"
                  fill="white"
                />
                <path
                  d="M-1.62445 15.2324L0.617684 16.3126L21.5877 -0.999277H18.4395L-1.62445 15.2324Z"
                  fill="#F50100"
                />
                <path
                  d="M22.227 13.9284L17.8259 15.7896L-0.0998707 2.02363L-2.42133 -0.742296L2.28497 -1.36439L9.59646 4.56778L15.4815 8.56467L22.227 13.9284Z"
                  fill="white"
                />
                <path
                  d="M22.0768 14.8643L19.8347 15.9444L10.9054 8.53233L8.25807 7.70419L-2.6447 -0.732803H0.503517L11.4002 7.50394L14.2946 8.49696L22.0768 14.8643Z"
                  fill="#F50100"
                />
                <mask id="path-9-inside-1_270_60980" fill="white">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.3611 -1.25H7.63889V5H-1.23279V10H7.63889V16.25H12.3611V10H21.2672V5H12.3611V-1.25Z"
                  />
                </mask>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.3611 -1.25H7.63889V5H-1.23279V10H7.63889V16.25H12.3611V10H21.2672V5H12.3611V-1.25Z"
                  fill="#F50100"
                />
                <path
                  d="M7.63889 -1.25V-2.5H6.38889V-1.25H7.63889ZM12.3611 -1.25H13.6111V-2.5H12.3611V-1.25ZM7.63889 5V6.25H8.88889V5H7.63889ZM-1.23279 5V3.75H-2.48279V5H-1.23279ZM-1.23279 10H-2.48279V11.25H-1.23279V10ZM7.63889 10H8.88889V8.75H7.63889V10ZM7.63889 16.25H6.38889V17.5H7.63889V16.25ZM12.3611 16.25V17.5H13.6111V16.25H12.3611ZM12.3611 10V8.75H11.1111V10H12.3611ZM21.2672 10V11.25H22.5172V10H21.2672ZM21.2672 5H22.5172V3.75H21.2672V5ZM12.3611 5H11.1111V6.25H12.3611V5ZM7.63889 0H12.3611V-2.5H7.63889V0ZM8.88889 5V-1.25H6.38889V5H8.88889ZM-1.23279 6.25H7.63889V3.75H-1.23279V6.25ZM0.0172119 10V5H-2.48279V10H0.0172119ZM7.63889 8.75H-1.23279V11.25H7.63889V8.75ZM8.88889 16.25V10H6.38889V16.25H8.88889ZM12.3611 15H7.63889V17.5H12.3611V15ZM11.1111 10V16.25H13.6111V10H11.1111ZM21.2672 8.75H12.3611V11.25H21.2672V8.75ZM20.0172 5V10H22.5172V5H20.0172ZM12.3611 6.25H21.2672V3.75H12.3611V6.25ZM11.1111 -1.25V5H13.6111V-1.25H11.1111Z"
                  fill="white"
                  mask="url(#path-9-inside-1_270_60980)"
                />
              </g>
            </g>
          </g>
        </svg>
      </button>
      <button className="w-5" onClick={handleSwitchLangugeVN}>
        <svg
          className="h-full w-auto"
          viewBox="0 0 20 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_270_60904"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="15"
          >
            <rect width="20" height="15" fill="white" />
          </mask>
          <g mask="url(#mask0_270_60904)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0H20V15H0V0Z"
              fill="#F7FCFF"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0V15H20V0H0Z"
              fill="#E31D1C"
            />
            <mask
              id="mask1_270_60904"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="20"
              height="15"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0V15H20V0H0Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_270_60904)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0387 9.98747L6.8194 12.0343L7.89921 8.45116L5.603 6.11012L8.76874 6.04097L10.1693 2.50357L11.4456 6.08769L14.6038 6.14282L12.2305 8.52677L13.3387 11.9355L10.0387 9.98747Z"
                fill="#FFD221"
              />
            </g>
          </g>
        </svg>
      </button>
    </div>
  )
}
