import { FC } from 'react'
import GrapeParser, { HTMLBuilderParserProps } from '.'
import { getValueByField } from './utils'
import { render } from 'micromustache'
import Link from 'next/link'

const DataLink: FC<HTMLBuilderParserProps> = props => {
  const { className, component, currentItem, globalData, data, extra } = props
  const { field } = component

  const value = getValueByField(data, field)
  const linkTemplate = component.linkTemplate
  const href = render(linkTemplate, value)

  const children = component.components?.map((component, index) => {
    return (
      <GrapeParser
        key={index}
        component={component}
        currentItem={currentItem}
        globalData={globalData}
        data={value}
        extra={extra}
      />
    )
  })

  // if relative link, use Link component
  if (href.startsWith('/')) {
    return (
      <Link href={href} passHref>
        <a className={className} {...component.attributes}>
          {children}
        </a>
      </Link>
    )
  }

  return (
    <a href={href} className={className} {...component.attributes}>
      {children}
    </a>
  )
}

export default DataLink
