import { FC } from 'react'
import { HTMLBuilderParserProps } from '.'
import { getValueByField } from './utils'
import Image from 'next/future/image'

const PlaceholderImage: FC<HTMLBuilderParserProps> = props => {
  const { className, component, data } = props
  const { field } = component

  const src = getValueByField(data, field)

  return (
    <Image
      className={className}
      {...component.attributes}
      src={`${process.env.NEXT_PUBLIC_API_URL}/assets/${src}`}
      alt={component.attributes?.alt}
      width="0"
      height="0"
      sizes="100vw"
    />
  )
}

export default PlaceholderImage
