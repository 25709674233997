import { isEmpty } from 'lodash'
import { FC, useEffect, useState } from 'react'
import NextImage, { ImageProps } from 'next/image'

interface IRenderNextImage extends ImageProps {}

const getMeta = (
  url: ImageProps['src'],
  cb: (err: string | Event, img?: HTMLImageElement) => void
) => {
  const img = new Image()
  img.onload = () => cb(null, img)
  img.onerror = err => cb(err)
  if (typeof url !== 'string') return
  img.src = url
}

type TDimensions = {
  width: number
  height: number
}

const RenderNextImage: FC<IRenderNextImage> = props => {
  const [dimensions, setDimensions] = useState<TDimensions>()
  useEffect(() => {
    getMeta(props.src, (_err, img) => {
      setDimensions({
        width: img?.naturalWidth,
        height: img?.naturalHeight,
      })
    })
  }, [props.src])
  return (
    !isEmpty(dimensions) && (
      <NextImage
        {...props}
        width={dimensions.width}
        height={dimensions.height}
      />
    )
  )
}

export default RenderNextImage
