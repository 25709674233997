import { IMetadata } from '@/ts/models'

export const DOMAIN = 'https://imeprial.com'
export const HOMEPAGE_TITLE = 'Imperial hotel and resort'
export const HOMEPAGE_DESCRIPTION =
  'The IMPERIAL Vung Tau Hotel and Resort is the first and the only 5-star hotel & resort with its own private beach and fabulous Beach Club in Vung Tau city.'
export const FAVICON = '/favicon.ico'
export const HOMEPAGE_OG_IMAGE = '/og-image.jpeg'
export const HOMEPAGE_OG_TITLE = HOMEPAGE_TITLE
export const HOMEPAGE_OG_DESCRIPTION = HOMEPAGE_DESCRIPTION
export const PAGE_NAME = 'Imperial hotel and resort'

export const DEFAULT_METADATA: IMetadata = {
  // Common metadata
  title: HOMEPAGE_TITLE,
  // Article Specific Metadata
  keywords: [],
  description: HOMEPAGE_DESCRIPTION,
  // Open Graph Metadata
  ogType: 'website',
  ogTitle: HOMEPAGE_TITLE,
  ogDescription: HOMEPAGE_DESCRIPTION,
  ogImage: HOMEPAGE_OG_IMAGE,
  ogSiteName: HOMEPAGE_TITLE,
  ogUrl: DOMAIN,
  // Twitter Card Metadata
  twitterUrl: DOMAIN,
  twitterTitle: HOMEPAGE_TITLE,
  twitterDescription: HOMEPAGE_DESCRIPTION,
  twitterImageSrc: HOMEPAGE_OG_IMAGE,
  locale: 'en_US',
  imageAlt: 'Imperial hotel and resort',
}
