import { useEffect, useState } from 'react'
import { LOCAL_STORAGE } from '@/ts/constant'
import { Components } from '@/ts/graphqlschema'
import { getCMSImageUrl } from '@/utils/image-utils/get-cms-image'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import styles from './editor-styles.module.css'
import moment from 'moment'

const Close = dynamic(() => import('../svgs/close'))

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

type AnnouncementModalProps = {
  isOpen: boolean
  onCloseModal?: any
  onClickCloseButton?: any
  modalData: Components
}

export function AnnouncementModal(props: AnnouncementModalProps) {
  const { modalData } = props
  const remindAfter = modalData.modal_remind_after || 1
  const modalExpireAt = localStorage.getItem(LOCAL_STORAGE.MODAL_EXPIRE_AT)
  const CURRENT_DATE = moment(Date()).format()
  const NEXT_EXPIRE_AT = moment(Date()).add(remindAfter, 'm').format()

  const [isOpenLocal, setIsOpenLocal] = useState<boolean>(false)

  const handleCloseModal = () => {
    // if (props.onClickCloseButton) {
    //   props.onClickCloseButton()
    // }
    setIsOpenLocal(false)
    localStorage.setItem(LOCAL_STORAGE.IS_OPEN_MODAL, 'false')
    localStorage.setItem(LOCAL_STORAGE.MODAL_EXPIRE_AT, NEXT_EXPIRE_AT)
  }

  /**
   * Get Modal LocalState Status
   */
  useEffect(() => {
    const isOpenModalLocalStorage = localStorage.getItem(
      LOCAL_STORAGE.IS_OPEN_MODAL
    )
    const IS_EXPIRED = CURRENT_DATE > modalExpireAt

    // If expiry date is null then set next expired
    if (!modalExpireAt) {
      localStorage.setItem(LOCAL_STORAGE.MODAL_EXPIRE_AT, NEXT_EXPIRE_AT)
    }

    if (isOpenModalLocalStorage === null) {
      setIsOpenLocal(true)
      localStorage.setItem(LOCAL_STORAGE.MODAL_EXPIRE_AT, NEXT_EXPIRE_AT)
      // localStorage.setItem(LOCAL_STORAGE.MODAL_EXPIRE_AT, CURRENT_DATE+)
    } else if (isOpenModalLocalStorage === 'false' && IS_EXPIRED) {
      setIsOpenLocal(true)
    } else {
      setIsOpenLocal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenLocal])

  return (
    <section
      title="announcement modal"
      className={`fixed w-screen bg-black bg-opacity-50 flex items-center justify-center z-50 top-0 left-0 ${
        isOpenLocal ? 'fixed h-screen' : 'h-0 hidden'
      }`}
    >
      <div className="w-11/12 h-5/6 md:w-11/12 xl:w-8/12 md:h-4/6 bg-lighter rounded-xl flex flex-col md:flex-row overflow-hidden drop-shadow-2xl">
        {/*IMAGES*/}
        <div className="w-full h-1/2 md:h-full relative">
          <Image
            className="h-full object-cover"
            src={getCMSImageUrl(modalData.image, 'lg')}
            alt={modalData.image?.description}
            layout="fill"
          />
          {/*<Image*/}
          {/*  className="w-full h-full object-contain"*/}
          {/*  src={imageUrl}*/}
          {/*  title={caption}*/}
          {/*  layout="fill"*/}
          {/*  objectFit={'contain'}*/}
          {/*  alt={caption}*/}
          {/*/>*/}
        </div>
        {/*CONTENT*/}
        <div className="relative w-full p-10 flex items-center justify-center">
          <div className="items-center flex justify-between flex-col">
            {/*CONTENT*/}
            <div className="py-6">
              <h6 className="font-alegreya text-primaryDark text-3xl font-normal italic">
                {modalData.subtitle}
              </h6>
              {/*TITLE*/}
              <h3 className="text-2xl">{modalData.title}</h3>
              {modalData.modal_content && (
                <div
                  className={styles.content}
                  dangerouslySetInnerHTML={{ __html: modalData.modal_content }}
                />
              )}
            </div>
            {/*FOOTER*/}
            <div className="w-full">
              <a
                className="button--primary w-full cursor-pointer block text-center"
                href={modalData.cta_link}
                target="_blank"
                rel="noreferrer"
                onClick={handleCloseModal}
              >
                BOOK NOW
              </a>
            </div>
          </div>
          {/*CLOSE BUTTON*/}
          <div className="absolute top-5 right-5 z-50">
            <button className="w-6" onClick={handleCloseModal}>
              <Close />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
